export const SPECS = {
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
  SupportCardTokenizationOnCartAndCheckout: 'specs.stores.SupportCardTokenizationOnCartAndCheckout',
  ShouldCustomizeCheckoutButton: 'specs.stores.ShouldCustomizeCheckoutButton',
  ShouldUseSideCart: 'specs.stores.ShouldUseSideCart',
  ShouldUseOpenSideCartApiInAddToCart: 'specs.stores.ShouldUseOpenSideCartApiInAddToCart',
  ShouldPreloadCurrentCart: 'specs.stores.ShouldPreloadCurrentCart',
  ShouldUseNewCartQueryAndMutations: 'specs.stores.ShouldUseNewCartQueryAndMutations',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
  UseChevronForItemOptions: 'specs.stores.UseChevronForItemOptions',
  MakeCartItemNameLink: 'specs.stores.MakeCartItemNameLink',
  CartItemMoreOptionsFont: 'specs.stores.CartItemMoreOptionsFont',
  NotShowThreeDotsWhenFinishedLoading: 'specs.stores.NotShowThreeDotsWhenFinishedLoading',
  SendTrackEventWhenCheckoutLoad: 'specs.stores.SendTrackEventWhenCheckoutLoad',
  ErrorMessage: 'specs.stores.AddErrorMessageInCouponWhenTheInputIsEmpty',
  AddSidePaddingToCartSettingsForDesktop: 'specs.stores.AddSidePaddingSettingsOnCartPageForDesktop',
  UsePageUrlOnItemThumbnail: 'specs.stores.UsePageUrlOnItemThumbnail',
  ShouldShowIconSizeSetting: 'specs.stores.ShouldShowIconSizeSetting',
  AddSlotToCartPage: 'specs.stores.AddSlotToCartPage',
  FixSSRInCart: 'specs.stores.FixSSRInCart',
} as const;
